.component {
    position:absolute;
    top:30%;
    left:50%;
    transform:translate(-50%, -50%);
    color: black;
    background: white;
    border: 1px solid #ADD8E6;
    border-radius: 25px;
    z-index: 1000;
}

.componentText {
    padding: 20px;
    text-align: center;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    padding: 20px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.logoutButton {
    margin: 20px;
}
